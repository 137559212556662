var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.b(null, { error: _vm.isShakeError && _vm.hasError, group: _vm.group })},[(!!_vm.label)?_c('label',{class:_vm.b('label'),attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:_vm.b('block', _vm.classWrap)},[_c('div',{class:_vm.b('wrap')},[(_vm.$slots.prefix)?_c('div',{class:_vm.b('prefix')},[_vm._t("prefix")],2):_vm._e(),_c('textarea',_vm._g(_vm._b({class:_vm.b('input', {
            suffix: _vm.$slots.suffix,
            prefix: _vm.$slots.prefix,
            error: _vm.hasError,
          }),attrs:{"id":_vm.id,"rows":_vm.rows},domProps:{"value":_vm.value}},'textarea',Object.assign({}, _vm.$attrs),false),Object.assign({}, _vm.$listeners,
          {input: function (event) { return _vm.$emit('input', event.target.value); },
          focus: function (event) { return _vm.$emit('focus', event); },
          blur: function (event) { return _vm.$emit('blur', event); }}))),_vm._v(" "),(_vm.$slots.suffix)?_c('div',{class:_vm.b('suffix')},[_vm._t("suffix")],2):_vm._e()]),_vm._t("default"),_c('transition',{attrs:{"name":"fade"}},[(_vm.hasError)?_c('div',{class:_vm.b('error')},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e()])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }